import { css } from '@emotion/core';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import '../../../graphql/fragments';
import SdMapPin from '../../../images/icons/sd-map-pin.svg';
import googleLoader from '../../../lib/google-loader';
import { SerializedLocationWithFormattedAddress } from '../../../lib/types/location';
import Button from '../../../ui/BaseButton';
import { COLORS } from '../../../ui/variables';
import { serializeLocationWithFormattedAddress } from '../../../utils/location';
import mapStyles from '../../map-styles';
import {
  Heading1,
  HeadingContainer,
  InfoWindowAddress,
  InfoWindowContentContainer,
  InfoWindowHeading,
  Map as MapContainer,
  Section,
} from './styles';

interface LocationsMapProps {
  zoom?: number;
}

const MAP_ID = 'all-locations';

const useAllLocations = () => {
  const data = useStaticQuery(graphql`
    query HomepageLocations {
      allContentfulPagesLocationTemplate(
        filter: { ghostLocation: { eq: false } }
        sort: { fields: isComingSoon }
      ) {
        nodes {
          ...LocationForMaps
        }
      }
    }
  `);

  return data.allContentfulPagesLocationTemplate.nodes.map(
    serializeLocationWithFormattedAddress
  );
};

const Map = ({ zoom = 100 }: LocationsMapProps) => {
  const locations = useAllLocations();

  useEffect(() => {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
      /*InfoWindow wrapper */
			.gm-style .gm-style-iw-c {
				border: 1px solid #c2c2c2 !important; /* Apply a 1px solid border */
				border-radius: 0 !important; /* Remove border radius */
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) !important; /* Smaller shadow */
				padding: 0px !important; /* Optional: Add some padding */
			}

			/* InfoWindow Header */
			.gm-style-iw-chr {
				height: 28px !important;
			}

			/* InfoWindow Content outer wrapper */
			.gm-style .gm-style-iw-d {
				padding: 0px !important;
			}
    `;
    document.head.appendChild(styleTag);

    return () => {
      document.head.removeChild(styleTag);
    };
  }, []);

  useEffect(() => {
    let map: google.maps.Map;
    let infoWindow: google.maps.InfoWindow | null = null;

    googleLoader.load().then(google => {
      map = new google.maps.Map(
        document.getElementById(MAP_ID) as HTMLElement,
        {
          zoom,
          styles: mapStyles,
          disableDefaultUI: true,
        }
      );

      const bounds = new google.maps.LatLngBounds();

      const markers: google.maps.Marker[] = [];
      locations.forEach((location: SerializedLocationWithFormattedAddress) => {
        const marker = new google.maps.Marker({
          position: location.position,
          map,
          title: location.name,
          icon: {
            url: SdMapPin,
          },
        });

        marker.addListener('click', () => {
          if (infoWindow) {
            infoWindow.close();
          }

          const container = document.createElement('div');

          ReactDOM.render(<InfoWindowContent location={location} />, container);

          infoWindow = new google.maps.InfoWindow({
            content: container,
          });

          infoWindow.open(map, marker);
        });

        markers.push(marker);
        bounds.extend(location.position);
      });

      map.fitBounds(bounds);
    });
  }, []);

  return (
    <Section>
      <HeadingContainer>
        <Heading1>Our Vet Clinic Locations</Heading1>
      </HeadingContainer>
      <MapContainer id={MAP_ID} />
    </Section>
  );
};

export default Map;

const InfoWindowContent: React.FC<{
  location: SerializedLocationWithFormattedAddress;
}> = ({ location }) => (
  <InfoWindowContentContainer>
    <InfoWindowHeading>{location.name}</InfoWindowHeading>
    <InfoWindowAddress>
      {location.formattedAddress.line1}
      <br />
      {location.formattedAddress.line2}
      <br />
      {location.formattedAddress.line3}
    </InfoWindowAddress>
    <Button
      type='external'
      href={location.path}
      color={COLORS.NEW_ORANGE}
      css={css`
        padding: 12px 26px;
        font-family: Gibson;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 22px;
        text-align: center;
        flex-shrink: 0;
        letter-spacing: 0px;
        width: fit-content;
      `}
    >
      Book now
    </Button>
  </InfoWindowContentContainer>
);
