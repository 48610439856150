import { LocationForMapsFragment } from '../../graphql-types';
import {
  SerializedLocation,
  SerializedLocationWithFormattedAddress,
} from '../lib/types/location';

const ADDRESS_ABBREVIATIONS: Record<string, string> = {
  road: 'Rd',
  street: 'St',
  avenue: 'Ave',
  boulevard: 'Blvd',
  circle: 'Cir',
  court: 'Ct',
  drive: 'Dr',
  lane: 'Ln',
  parkway: 'Pkwy',
  place: 'Pl',
  square: 'Sq',
  terrace: 'Ter',
  way: 'Way',
};

const contractAddressWords = (address: string): string => {
  return address.replace(/\b\w+\b/g, word => {
    const lowerWord = word.toLowerCase();
    return ADDRESS_ABBREVIATIONS[lowerWord] || word;
  });
};

export const serializeLocation = (
  location: LocationForMapsFragment
): SerializedLocation => ({
  name: location.locationName,
  isComingSoon: location.isComingSoon,
  address: `${location.addressLine1}${
    location.addressLine2 ? `, ${location.addressLine2}` : ''
  }`,
  path: location.path,
  position: {
    lat: location.locationCoordinates.lat,
    lng: location.locationCoordinates.lon,
  },
});

export const serializeLocationWithFormattedAddress = (
  location: LocationForMapsFragment
): SerializedLocationWithFormattedAddress => {
  // addressLine1 is typically the street address
  // addressLine2 is typically "City, State ZIP"
  const addressLine2Parts = location.addressLine2?.split(' ') || [];
  const zipCode = addressLine2Parts.pop();
  const line2 = addressLine2Parts.join(' ').replace(',', '');

  return {
    name: location.locationName,
    isComingSoon: location.isComingSoon,
    path: location.path,
    position: {
      lat: location.locationCoordinates.lat,
      lng: location.locationCoordinates.lon,
    },
    formattedAddress: {
      line1: contractAddressWords(location.addressLine1),
      line2: line2,
      line3: zipCode || '',
    },
  };
};
